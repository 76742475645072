import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/appSidebar",
      props: true,
      component: () => import("./components/Common/appSidebar"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "/DashboardPage",
          name: "DashboardPage",
          meta: {
            requireAuth: true,
            
          },
          component: () => import("./components/Dashboard/DashboardPage.vue"),
        },

        {
          path: "/TraderDashBoard",
          name: "TraderDashBoard",
          meta: {
            requireAuth: true,
            
          },
          component: () => import("./components/Dashboard/TraderDashBoard.vue"),
        },
        {
          path: "/ProductsPage",
          name: "ProductsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Dashboard/ProductsPage.vue"),
        },

        {
          path: "/products",
          name: "products",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/product/ProductsPage.vue"),
        },


        {
          path: "/profile",
          name: "profile",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/profile/ProfilePage.vue"),
        },
      

        {
          path: "/MyProfile",
          name: "MyProfile",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/profile/MyProfile.vue"),
        },
        
        {
          path: "/AddressBook",
          name: "AddressBook",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/profile/AddressBook.vue"),
        },

        {
          path: "/Paymentoptions",
          name: "Paymentoptions",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/profile/Paymentoptions.vue"),
        },

        {
          path: "/Paymentoptions",
          name: "Paymentoptions",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/profile/Paymentoptions.vue"),
        },

        {
          path: "/MyOrders",
          name: "MyOrders",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/profile/MyOrders.vue"),
        },

        {
          path: "/Cancellations",
          name: "Cancellations",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/profile/Cancellations.vue"),
        },
        {
          path: "/customOrders",
          name: "customOrders",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/profile/customOrders.vue"),
        },
        {
          path: "/appDashboard",
          name: "appDashboard",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Common/appDashboard"),
        },


        {
          path: "/cart",
          name: "cart",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/cart/CartsPage"),
        },
        {
          path: "/wishlist",
          name: "wishlist",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/wishlist/WishListpage.vue"),
        },
        {
          path: "/CustomOrder",
          name: "CustomOrder",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/customorder/CustomOrder.vue"),
        },
        {
          path: "/detailedView",
          name: "detailedView",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/customorder/detailedView.vue"),
        },
        {
          path: "/addlot",
          name: "addlot",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/lots/AddLots.vue"),
        },
      ],
    },
    {
      path: "/",
      name: "MainPage",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/MainPage"),
    },
    {
      path: "/Privacypolicy",
      name: "Privacypolicy",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/Privacypolicy"),
    },

    {
      path: "/SupportPage",
      name: "SupportPage",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/SupportPage"),
    },
    {
      path: "/LoginPage",
      name: "LoginPage",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/LoginPage"),
    },

    {
      path: "/TermsAndConditions",
      name: "TermsAndConditions",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Policy/TermsAndConditions.vue"),
    },

    {
      path: "/ReturnPolicy",
      name: "ReturnPolicy",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Policy/ReturnPolicy.vue"),
    },


    {
      path: "/CancellationPolicy",
      name: "CancellationPolicy",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Policy/CancellationPolicy.vue"),
    },
    {
      path: "/ShippingPolicy",
      name: "ShippingPolicy",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Policy/ShippingPolicy.vue"),
    },
    {
      path: "/RefundPolicy",
      name: "RefundPolicy",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Policy/RefundPolicy.vue"),
    },

    {
      path: "/PrivacyPolicy",
      name: "PrivacyPolicy",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Policy/PrivacyPolicy.vue"),
    },

    {
      path: "/Registration",
      name: "Registration",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/Registration"),
    },
    {
      path: '/OtpPage',
      name: 'OtpPage',
      meta: {
        requireAuth: false,
      },
      component: function () {
        return import(/* webpackChunkName: "about" */ './components/Login/OtpPage.vue')
      }
    },

    {
      path: "/ServerError",
      name: "ServerError",
      props: true,
      component: () => import("./components/Common/500"),
    },
    {
      path: "*",
      name: "404PageNotFound",
      props: true,
      meta: {
        requiresAuth: false,
      },
      component: () => import("./components/Common/404"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((route) => route.meta.requireAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    console.log(from);
    next({ name: "LoginPage", params: { lastPage: from } });
    return;
  }
if(
  to.name=="LoginPage" && 
  store.state.isLoggedIn == true 
){
  next({name:"DashboardPage"});
  return;
}
if(
  to.name=="MainPage" && 
  store.state.isLoggedIn == true 
){
  next({name:"DashboardPage"});
  return;
}


  if (store.state.isLoggedIn == true) {
    axios({
      method: "GET",
      url: "/authenticate",
      headers: {
        "token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        // var role = response.data.role;
        // store.commit("role", response.data.role)
        if (!response.data.status) {
          store.commit("sessionOut", true);
          return;
        }
      })
      .catch((err) => {
        var msg = err;
        console.log(msg);
      });
  }
  if (to.name == "LoginPage" && store.state.isLoggedIn == true) {
    next({ name: "DashboardPage" });
    return;
  }
  next();
});
export default router